// src/App.js
import './App.css';
import { useState } from 'react';
import Survey from './Survey';

function App() {
  const [showSurvey, setShowSurvey] = useState(true);

  const handleDismiss = () => {
    setShowSurvey(false);
    console.log("Survey dismissed!");
  };

  const handleSubmit = (value) => {
    setShowSurvey(false);
    console.log("User submitted:", value);
  };

  return (
    <div className="App">
      <h1>This is our survey tutorialx</h1>
      {showSurvey && (
        <Survey
          title="Rate our service"
          onDismiss={handleDismiss}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

export default App;
